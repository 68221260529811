html, body {
  height: 100%;
  margin: 0;
}

/* Navbar Styles */
.navbar-text-color {
  font-size: 1rem;
}

.navbar-text-color {
  color: #222222 !important; /* Use !important to override any existing styles */
}

.navbar-link {
  font-size: 1.2rem;
}


/* Footer Styles */
.footer {
  background-color: #222222; /* Match the navbar's background color */
  padding: 0.5rem 0; /* Adjust padding to match navbar thickness */
}

.footer-logo {
  max-height: 40px; /* Adjust this value as per your design */
  width: auto; /* Maintain aspect ratio */
}

.footer .container {
  display: flex;
  justify-content: center; /* Horizontally center the content */
  align-items: center; /* Vertically center the content */
  min-height: 56px; /* Adjust this to match the navbar's height or as needed */
}

/*Ensure Footer stays at the bottom*/

/* Ensure the root element fills the height */
#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#page-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

#content-wrap {
  flex: 1;
}