/* @import url('https://fonts.googleapis.com/css2?family=Afacad&display=swap'); */

@import url('https://fonts.googleapis.com/css2?family=Afacad:ital,wght@0,400;0,500;1,400&display=swap');

body {
  margin: 0;
  font-family: 'Afacad', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 20px;

}

code {
  font-family: 'Afacad', sans-serif;
}
