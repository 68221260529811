/* Utility Classes */
.btn-link {
    text-decoration: none;
  }
  
  /* Badge, Citation & Chart Styles */
  .searchable-container2 {
    display: flex;
    flex-direction: row;
    align-items: start;
    width: 90%;
    margin: auto;
    padding: 20px;
  }
  
  .citation {
    color: red;
  }
  
  .chart-container {
    position: relative;
    overflow: hidden;
  }
  
  .citation-ref {
    font-size: smaller;
    vertical-align: super;
  }
  
  .custom-badge {
    font-size: 1rem !important;
    font-weight: normal !important;
    color: white !important;
  }
  
  /* Shadow Effects */
  .shadow-border {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .shadow-border-bottom-left {
    box-shadow:
      -2px 0 5px -2px rgba(0, 0, 0, 0.2),
      0 2px 5px -2px rgba(0, 0, 0, 0.2);
  }
  
  /* Slider Container */
  .slider-container {
    width: 90%;
    margin: 0 auto;
    overflow: hidden;
    padding: 0;
  }
  
  @media (max-width: 768px) {
    .slider-container {
      max-width: 600px;
    }
  }
  
  /* Home & Base Containers */
  .home-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: start;
    width: 90%;
    margin: auto;
    padding: 20px;
  }
  
  .base-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: start;
    width: 90%;
    margin: auto;
  }
  
  .base-container-vert {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    margin: auto;
  }
  
  /* Flex Containers */
  .content-flex-container {
    display: flex;
    align-items: stretch;
  }
  
  .text-content {
    flex: 1.5;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding-right: 20px;
    font-size: 1.5rem;
  }
  
  .image-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image-content img {
    width: 100%;
    height: auto;
  }
  
  .sketch-container {
    flex: 1;
  }
  
  /* Feature Items */
  .feature-item {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }
  
  /* Feature List */
  .feature-list {
    list-style-type: disc;
    padding-left: 20px;
    margin-bottom: 20px;
    text-align: left;
    width: 100%;
  }
  
  /* Plans & Account Options (Desktop) */
  .plans-container {
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    gap: 3%;
    width: 100%;
  }
  
  .account-option {
    border: 2px solid #CCCCCC;
    border-radius: 20px;
    padding: 20px;
    max-width: 90%;
    flex: 1;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  
  /* Key Insights */
  .key-insights {
    border: 2px solid #CCCCCC;
    border-radius: 20px;
    padding: 20px;
    flex: 1;
    box-sizing: border-box;
    max-width: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
  }
  
  .key-insights h4 {
    text-align: center;
    width: 100%;
  }
  
  /* Navigation Tabs & Pills */
  .nav-tabs .nav-link,
  .nav-pills .nav-link {
    color: #222222 !important;
  }
  
  .nav-tabs .nav-link.active,
  .nav-pills .nav-link.active {
    font-weight: 500 !important;
    color: #220138 !important;
  }
  
  /* Button Styles */
  .btn-custom {
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s ease;
    font-size: 1.3rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .btn-issue-tracker {
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s ease;
    font-size: 1.3rem;
  }
  
  .btn-fixture {
    padding: 5px 10px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s ease;
    font-size: 1.2rem;
  }
  
  .btn-sidebar {
    padding: 5px 15px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s ease;
    font-size: 1.2rem;
  }
  
  .btn-circle {
    padding: 5px 10px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s ease;
    font-size: 1.2rem;
  }
  
  .btn-white {
    background-color: white;
    color: #222222;
  }
  
  .btn-light-gray {
    background-color: #F8F9FA;
    color: #222222;
  }
  
  .btn-mid-gray {
    background-color: #CCCCCC;
    color: #222222;
  }
  
  .btn-charcoal-gray {
    background-color: #222222;
    color: #F8F9FA;
  }
  
  .btn-black {
    background-color: black;
    color: #F8F9FA;
  }
  
  /* Margin & Padding Utilities */
  .mr-05 {
    margin-right: 0.5rem;
  }
  
  .mr-1 {
    margin-right: 1rem;
  }
  
  .mr-2 {
    margin-right: 2rem;
  }
  
  .ml-1 {
    margin-left: 1rem;
  }
  
  .ml-2 {
    margin-left: 2rem;
  }
  
  .mu-1 {
    margin-top: 1rem;
  }
  
  .mb-1 {
    margin-bottom: 1rem;
  }
  
  .mu-2 {
    margin-top: 2rem;
  }
  
  .mb-2 {
    margin-bottom: 2rem;
  }
  
  /* Custom Checkbox */
  .custom-checkbox-container {
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
  }
  
  .custom-checkbox {
    width: 20px;
    height: 20px;
    border: 1px solid #ccc;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
  }
  
  .custom-checkbox.checked {
    background-color: #000;
    border-color: #000;
  }
  
  .custom-checkbox .checkmark {
    color: #fff;
    line-height: 0;
  }
  
  .custom-checkbox-label {
    color: #333;
    user-select: none;
  }
  
  /* Hover Effects */
  .btn-custom:hover {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  }
  
  /* --- New Grid Containers --- */
  /* Used for the Backed By section */
  .grid-container {
    display: grid;
    text-align: center;
    justify-items: center;
    align-items: start;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  @media (max-width: 768px) {
    .grid-container {
      grid-template-columns: 1fr;
    }
  }
  
  @media (min-width: 769px) {
    .grid-container {
      grid-template-columns: 1fr 1fr;
    }
  }
  
  /* Used for the Key Features section */
  .grid-container-features {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    justify-items: stretch;
    align-items: stretch;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  @media (min-width: 769px) {
    .grid-container-features {
      grid-template-columns: 1fr 1fr;
    }
  }
  
  /* Trusted Logo Style */
  .trusted-logo {
    display: block;
    margin: 0 auto;
    max-width: 80%;
    pointer-events: none;
  }
  
  /* Global Alert Style */
  .global-alert {
    background-color: #222222;
    color: #F8F9FA;
    padding: 10px 20px;
    text-align: center;
  }
  
  /* Mobile Overrides (placed at the end for higher specificity) */
  @media (max-width: 768px) {
    .home-container {
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
    
    /* Testimonial (plans-container) mobile styles */
    .plans-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px !important;  /* 20px gap between testimonial boxes */
      width: 100%;
    }
    
    /* Ensure testimonial boxes do not add extra margin */
    .plans-container .account-option {
      margin-bottom: 0 !important;
    }
    
    /* Mobile override for account-option */
    .account-option {
      width: 90%;
      max-width: 500px;
      margin: 0 auto;
      flex: unset;
    }
    
    .feature-item {
      width: 90%;
      max-width: 400px;
      margin: 0 auto;
    }
    
    .text-content h1 {
      font-size: 2rem;
    }
    
    .text-content h2 {
      font-size: 1.85rem;
    }
    
    .text-content h3 {
      font-size: 1.7rem;
    }
    
    .btn-custom {
      padding: 8px 15px;
    }
    
    .content-flex-container {
      flex-direction: column;
    }
    
    .text-content,
    .image-content {
      padding: 10px 0;
    }
    
    .hide-on-mobile {
      display: none;
    }
  }

/* --- Reusable Hover Effect --- */
.hover-lift {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.hover-lift:hover {
  transform: translateY(-5px);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
}
