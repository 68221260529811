/* SideBar Styles */
.sidebar {
    background-color: #F8F9FA; /* Light gray background color */
    padding: 1rem; /* Add some padding */
    box-sizing: border-box; /* Include padding in the width and height calculations */
    border-radius: 4px; /* Border radius */
    font-size: 1.1rem;
}

.fixed-width {
    width: 20vw; /* Set width to 20% of the viewport width */
    max-width: 250px;
    position: sticky; /* Make it stick during scrolling */
    top: 0; /* Position it at the top */
}

.sidebar .search-buttons {
    display: flex;
    justify-content: center; /* Centers horizontally */
    width: 100%;
    /* align-items: center; Centers vertically if needed */
}

.dropdown, .date-picker {
    margin-bottom: 1rem; /* Adds a 1rem bottom margin */
    max-width: 100%;
    padding: 0;
}

.date-picker input {
    width: 100%;
    padding: 8px 12px; /* Adjust padding to match react-select, if needed */
    border: 1px solid #cccccc; /* Border color similar to react-select */
    border-radius: 4px; /* Border radius to match react-select */
    color: #333333; /* Text color similar to react-select */
}

@keyframes bookmarkBounce {
    0% {
      transform: scale(1);
    }
    30% {
      transform: scale(1.3);
    }
    50% {
      transform: scale(0.9);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .bookmark-animation {
    animation: bookmarkBounce 0.5s ease-in-out;
  }
  


@media (max-width: 768px) {

    .fixed-width {
        width: 90vw; /* Set width to 20% of the viewport width */
        max-width: 300px;
        position: sticky; /* Make it stick during scrolling */
        top: 0; /* Position it at the top */
    }

    .toggle-sidebar {
        /* Style your minimize/maximize button here */
        position: absolute;
        top: 0;
        right: 0;
    }
}


/* DataTable Styles */
.data-table {
    flex-grow: 1; /* Takes up the remaining space */
    /* font-size: 1.1rem; */
    padding-left: 0.5rem;

}


.column-width-small {
    width: 10%; /* Small column */
}

.column-width-source {
    width: 12.5%; /* Small column */
}

.column-width-medium {
    width: 15%; /* Medium column */
}

.column-width-new {
    width: 17%; /* Medium column */
}

.column-width-large {
    width: 30%; /* Large column */
}

/* SearchableContainer Styles */
.searchable-container {
    display: flex; /* Enables flexbox layout */
    width: 100%;
}

   
@media (max-width: 768px) {
    .searchable-container {
        flex-direction: column; /* Stack children vertically */
        align-items: center; /* Center children horizontally in the flex container */
        justify-content: center; /* Center children vertically in the flex container */
    }
}

.error-message {
    color: red;             /* Make the text red */
    text-align: center;     /* Center the text */
    margin-top: 10px;       /* Optional: add some space above the message */
    /* Additional styling as needed */
}


.notification-banner {
    background-color: rgba(34, 34, 34, 0.95); /* Set the opacity (alpha) to 0.8 */
    color: #F8F9FA;
    text-align: center;
    padding: 15px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    font-size: 1.5rem;
    text-align: center;
}